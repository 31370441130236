<template>
  <div class="pb-2 mb-md-4">
    <b-overlay
      :show="loading || loadingRefetchBooking"
      rounded="sm"
      no-fade
      variant="light"
      spinner-variant="info"
      spinner-type="grow"
      :opacity="0.9"
      no-center
    >
      <template #overlay>
        <div class="text-center py-2 mt-4">
          <p
            id="cancel-label"
            class="font-weight-bolder text-airline"
          >
            {{ $t('reservation.loading2') }}
          </p>
          <div class="text-center">
            <b-spinner variant="info" />
          </div>
        </div>
      </template>

      <div
        class="d-flex flex-column gap-1 w-100 pb-50"
        style="position: sticky; top: 50px; z-index: 10;"
      >
        <BAlert
          v-if="isLoadBookingFail"
          show
          variant="danger"
          class="px-2 py-50 mb-0"
          style="background-color: #F8E4E4 !important;"
        >
          <span class="font-weight-bolder">
            Đây là dữ liệu được lưu trên hệ thống Airdata. Booking hãng đã bị xoá.
          </span>
        </BAlert>
        <BAlert
          v-if="isItinerariesSC"
          show
          variant="danger"
          class="px-2 py-50 mb-0"
          style="background-color: #F8E4E4 !important;"
        >
          <span class="font-weight-bolder">
            Vé có hành trình đổi giờ bay mới (SC), vui lòng xác nhận giờ bay mới.
          </span>
        </BAlert>
        <BAlert
          v-if="(!loading && getBookingData && isLockedModify)"
          show
          variant="danger"
          class="px-2 py-50 mb-0"
          style="background-color: #F8E4E4 !important;"
        >
          Hệ thống Vietnam Airlines khoá thanh toán, hoàn huỷ booking từ 23:30 đến hết 24:00 trong ngày !!!
        </BAlert>
        <BAlert
          v-if="(!loading && getBookingData && canIssueLimit === false && !isBookingOutSystem)"
          show
          variant="danger"
          class="px-2 py-50 mb-0"
          style="background-color: #F8E4E4 !important;"
        >
          Tài khoản đại lý của bạn không có quyền thanh toán booking. Vui lòng liên hệ đại lý cấp trên.
        </BAlert>
        <BAlert
          v-if="isPastDayBooking"
          show
          variant="danger"
          class="px-2 py-50 font-weight-bolder mb-0"
          style="background-color: #F8E4E4 !important;"
        >
          <span>Không thể thanh toán và chỉnh sửa vé qua ngày bay.</span>
        </BAlert>
        <BAlert
          v-else-if="isBookingOutSystem"
          show
          variant="danger"
          class="px-2 py-50 font-weight-bolder mb-0"
          style="background-color: #F8E4E4 !important;"
        >
          <span v-if="isBookingDeactive">
            <div
              v-if="isRoleF1"
              class="d-flex-between"
            >
              <div>
                <div>Booking đã được đồng bộ vào hệ thống.</div>
                <div>Vui lòng mở khoá để thanh toán và chỉnh sửa booking.</div>
              </div>
              <BButton
                variant="success"
                @click="handleUnlockOutsideBooking"
              >
                Mở khoá
              </BButton>
            </div>
            <div v-else>
              <div>
                <div>Booking đã được đồng bộ vào hệ thống.</div>
                <div>Vui lòng liên hệ Booker mở khoá để thanh toán và chỉnh sửa booking.</div>
              </div>
            </div>
          </span>
          <span v-else>Không thể thanh toán và chỉnh sửa booking ngoài.</span>
        </BAlert>
      </div>
      <b-tabs
        v-if="getBookingData"
        class="mb-0"
        nav-class="mb-50"
        lazy
      >
        <b-tab
          :title="$t('reservation.bookingDetail')"
          title-link-class="font-medium-1 fw-800"
          active
        >
          <DetailComponent
            :reservations-data="getBookingData"
            :shorten-booking-text="bookingText"
            :is-booking-out-system="isBookingOutSystem"
            :is-hide-function-button="isHideFunctionButton"
            :can-issue-limit="canIssueLimit"
            :is-locked-modify="isLockedModify"
          />
        </b-tab>

        <b-tab
          title-link-class="font-medium-1 fw-800"
          :title="$t('reservation.shortenBooking.titleBooking')"
          @click="getShortenBookingHandle"
        >
          <b-tabs
            v-if="getBookingData.source.includes('1S')"
            class="mb-1"
            pills
          >
            <b-tab
              title-link-class="font-medium-1 fw-700"
              :title="$t('flight.shortenBooking')"
              active
            >
              <div class="my-1">
                <ShortenBooking
                  :text-type="'Booking'"
                  :reservations-data="getBookingData"
                  :shorten-text="bookingText"
                  :loading="loadingShortenBooking"
                  @get-shorten-booking-handle="getShortenBookingHandle"
                />
              </div>
            </b-tab>

            <b-tab
              v-if="baggagesText"
              title-link-class="font-medium-1 fw-700"
              :title="$t('flight.shortenBaggages')"
            >
              <div class="my-1">
                <ShortenBooking
                  :text-type="'Baggages'"
                  :is-history="true"
                  :reservations-data="getBookingData"
                  :shorten-text="baggagesText"
                  :loading="loadingShortenBooking"
                  @get-shorten-booking-handle="getShortenBookingHandle"
                />
              </div>
            </b-tab>

            <b-tab
              v-if="fareRulesText"
              title-link-class="font-medium-1 fw-700"
              :title="$t('flight.shortenFareRules')"
            >
              <div class="mt-1 mb-5 pb-2">
                <ShortenBooking
                  :text-type="'FareRules'"
                  :is-history="true"
                  :reservations-data="getBookingData"
                  :shorten-text="fareRulesText"
                  :loading="loadingShortenBooking"
                  @get-shorten-booking-handle="getShortenBookingHandle"
                />
              </div>
            </b-tab>
          </b-tabs>

          <div
            v-else
            class="my-1"
          >
            <ShortenBooking
              :text-type="'Booking'"
              :reservations-data="getBookingData"
              :shorten-text="bookingText"
              :loading="loadingShortenBooking"
              @get-shorten-booking-handle="getShortenBookingHandle"
            />
          </div>
        </b-tab>

        <b-tab
          v-if="getBookingData.source.includes('1S') || historyText"
          title-link-class="font-medium-1 fw-800"
          :title="$t('reservation.history.title')"
        >
          <ShortenBooking
            :text-type="'History'"
            :is-history="true"
            :reservations-data="getBookingData"
            :shorten-text="historyText"
            :loading="loadingShortenBooking"
            @get-shorten-booking-handle="getShortenBookingHandle"
          />
        </b-tab>

        <b-tab
          v-if="canIssueLimit && getBookingData && getBookingData.isDomestic && !isEmpty(getBookingData.itineraries)"
          title-link-class="font-medium-1 fw-800"
          :title="$t('reservation.dkve')"
        >
          <FareRules :reservations-data="getBookingData" />
        </b-tab>

        <b-tab
          v-if="canIssueLimit && getBookingData && ['1G'].includes(getBookingData.source) && !isEmpty(getBookingData.itineraries)"
          title-link-class="font-medium-1 font-weight-bolder"
          :title="$t('reservation.dkve')"
          @click="getFareRulesHandle(getBookingData)"
        >
          <BCard
            v-for="(tripData, index) of dataFareRules"
            :key="index"
            header-bg-variant="light-info"
            header-class="py-75 mb-75"
            class="border"
          >
            <template #header>
              <div class="fw-700 text-airline">
                {{ tripData.trip[0].departure.iataCode }}-{{ tripData.trip[tripData.trip.length - 1].arrival.iataCode }}
                ({{ convertISODateTime(tripData.trip[0].departure.at, tripData.trip[0].departure.timeZone).date }})
              </div>
            </template>

            <ShortenBooking
              :text-type="'FareRules'"
              :is-history="false"
              :reservations-data="getBookingData"
              :shorten-text="tripData.fareRules"
              :loading="loadingShortenBooking"
              @get-shorten-booking-handle="getFareRulesHandle(getBookingData)"
            />
          </BCard>
        </b-tab>

        <template #tabs-end>
          <li
            role="presentation"
            class="nav-item ml-auto d-flex-center flex-wrap gap-2 mt-25 mt-md-0"
            :class="isMobileView ? 'w-100 justify-content-center' : ''"
          >
            <b-button
              v-if="suggestVisible && !isHideResell"
              variant="primary"
              class="px-50 px-md-1 text-nowrap text-truncate pulse-button"
              pill
              size="sm"
              @click="()=>$bvModal.show('suggest-shop-modal')"
            >
              <div class="d-flex-center gap-1">
                <feather-icon
                  class="mr-50 d-none d-md-inline"
                  icon="SearchIcon"
                  size="14"
                />
                {{ $t('suggestShopVJ') }}
              </div>
            </b-button>
            <b-button
              v-if="isRoleF2 && getBookingData.airlines.split(' | ').some(e => airlineSupportsCC.includes(e)) && !isHideSupportRequest"
              variant="primary"
              pill
              size="sm"
              @click="()=>$bvModal.show('create-support-modal')"
            >
              <div class="d-flex-center gap-1">
                {{ $t(supportReqId ? 'supportReq.btn.editReq' : 'supportReq.form.title') }}
              </div>
            </b-button>

            <!-- ANCHOR NOTE -->
            <b-button
              v-if="!hideFeature"
              variant="outline-primary"
              class="px-50 px-md-1 text-nowrap text-truncate"
              pill
              size="sm"
              :disabled="isBookingOutSystem"
              @click="editNote($event.target)"
            >
              <div class="d-flex-center gap-1">
                <IAmIcon
                  icon="pencil"
                  size="15"
                />
                {{ $t('reservation.note') }}
              </div>
            </b-button>

            <!-- ANCHOR ASSIGN BOOKING -->
            <b-button
              v-if="isRoleF1 && !hideFeature && !isHideFunctionButton"
              variant="primary"
              class="px-50 px-md-1 text-nowrap text-truncate"
              pill
              size="sm"
              :disabled="isBookingOutSystem"
              @click="e => openAssignBooking(e, getBookingData)"
            >
              {{ $t('reservation.assignBooking.title') }}
            </b-button>

            <!-- ANCHOR AUTO ISSUE -->
            <b-button
              v-if="getBookingData.status === 'HOLD' && sourcesCanAutoIssue.includes(getBookingData.source) && !hideFeature && !isHideFunctionButton"
              class="px-50 px-md-1 text-nowrap text-truncate"
              variant="primary"
              pill
              size="sm"
              :disabled="!canAccess('booking.issueTicket') || isBookingOutSystem || !canIssueLimit"
              @click="e => openSetIssue(e, getBookingData)"
            >
              {{ getBookingData.autoIssueTime ? $t('reservation.historyActions.SET_AUTO_ISSUE_AND_CANCEL') :
                $t('reservation.historyActions.SET_AUTO_ISSUE') }}
            </b-button>

            <!-- ANCHOR CANH VÉ RẺ -->
            <b-button
              v-if="canSetLowFare() && !hideFeature && !isHideFunctionButton"
              class="px-50 px-md-1 text-nowrap text-truncate"
              variant="primary"
              pill
              size="sm"
              :disabled="isBookingOutSystem || !canIssueLimit"
              @click="handleShowModalLowFareTracking"
            >
              {{ $t('reservation.lowFare.lowfareTracking') }}
            </b-button>

            <b-button
              v-if="isF3canSendMailAirline && !isHideEmailButton && !hideFeature && !isHideFunctionButton"
              class="px-50 px-md-1 text-nowrap text-truncate"
              variant="primary"
              pill
              size="sm"
              :disabled="getBookingData.status === 'CANCEL' || !canAccess('booking.sendEmail') || isBookingOutSystem || !canIssueLimit"
              @click="handleShowModalSendEmail"
            >
              {{ $t('reservation.historyActions.SEND_EMAIL') }}
            </b-button>
            <b-button
              v-if="getBookingData.isActive !== undefined && !hideFeature && !isHideFunctionButton"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-primary.window
              variant="primary"
              :disabled="isBookingOutSystem"
              :title="isMobileView ? '' : (getBookingData.isActive ? $t('reservation.hideBooking') : $t('reservation.showBooking'))"
              class="btn-icon p-50"
              size="sm"
              pill
              @click="showConfirmToggleHideBooking(getBookingData, !getBookingData.isActive)"
            >
              <feather-icon
                v-if="getBookingData.isActive"
                icon="EyeIcon"
                size="18"
              />
              <feather-icon
                v-else
                icon="EyeOffIcon"
                size="18"
              />
            </b-button>

            <!-- ANCHOR Ẩn tính năng -->
            <b-button
              v-if="!isHideFunctionButton"
              class="btn-danger px-50 px-md-1 text-nowrap text-truncate"
              pill
              variant="danger"
              size="sm"
              @click="toggleHideFeature"
            >
              {{ $t(`reservation.${hideFeature ? 'showFeature' : 'hideFeature'}`) }}
            </b-button>
          </li>
        </template>
      </b-tabs>

      <ModalSms
        v-if="getBookingData"
        :ticket-data="getBookingData"
      />

      <AutoIssueModal
        v-if="getBookingData"
        :booking-data.sync="getBookingData"
        @refetch-data="refetchBookingData"
      />

      <SendEmailModal />

      <ModalLowFareTracking
        v-if="getBookingData && canSetLowFare() && !isEmpty(getBookingData.bookingRequest)"
        :booking-data="getBookingData"
      />

      <AssignBookingModal
        v-if="getBookingData"
        :booking-data.sync="getBookingData"
        @refetch-data="refetchBookingData"
      />

      <NoteModal
        v-if="getBookingData"
        :reservation-data.sync="getBookingData"
        :is-modify="true"
      />
      <SuggestShopVJModal
        :passenger-data-to-add.sync="paxLists"
        :airline-code="airlineCode"
        @set-visible="onSetVisible"
      />

      <CreateModal
        :id.sync="supportReqId"
        :booking.sync="getBookingData"
        @set-id="(id)=>supportReqId= id"
      />
    </b-overlay>
  </div>
</template>

<script>
import {
  BTab, BTabs, BCard, BOverlay, BButton, BAlert, BSpinner, VBTooltip,
} from 'bootstrap-vue'
import {
  onUnmounted, watch, ref, computed,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'

import { apiSupportRequest, apiBooking } from '@/api'
import { isDomesticFlight } from '@/constants/selectOptions'
import { MAMA_AGENCY_ID } from '@/constants/topup'
import router from '@/router'
import store from '@/store'
import VueI18n from '@/libs/i18n'
import env from '@/libs/env'

import { convertShortTrip, formatCurrency, convertISODateTime } from '@core/utils/filter'

import reservationStoreModule from '@reservation/reservationStoreModule'
import useReservationHandle from '@reservation/useReservationHandle'

import useSupportReqHandle from '@supportRequest/useSupportReqHandle'

import useToast from '@useToast'

export default {
  name: 'ReservationModify',
  directives: { 'b-tooltip': VBTooltip },
  components: {
    BTab,
    BTabs,
    BCard,
    BOverlay,
    BButton,
    BAlert,
    BSpinner,
    CreateModal: () => import('@supportRequest/create/CreateModal.vue'),
    DetailComponent: () => import('@reservation/reservation-modify/components/detail/index.vue'),
    ShortenBooking: () => import('@reservation/reservation-modify/components/shorten-booking/index.vue'),
    FareRules: () => import('@reservation/reservation-modify/components/fare-rules/index.vue'),
    ModalSms: () => import('@reservation/modals/ModalSms.vue'),
    ModalLowFareTracking: () => import('@reservation/modals/ModalLowFareTracking.vue'),
    AutoIssueModal: () => import('@reservation/reservation-list/AutoIssueModal.vue'),
    AssignBookingModal: () => import('@reservation/reservation-modify/AssignBookingModal.vue'),
    SendEmailModal: () => import('@reservation/reservation-list/SendEmailModal.vue'),
    NoteModal: () => import('@reservation/reservation-list/NoteModal.vue'),
    SuggestShopVJModal: () => import('@/views/apps/flight-v2/reservation/SuggestShopVJModal.vue'),
  },
  setup(_, { root }) {
    const RESERVATION_APP_STORE_MODULE_NAME = 'app-reservation'
    const { toastError } = useToast()
    const paxLists = ref([])
    const airlineCode = ref('')
    const supportReqId = ref(null)
    const suggestVisible = ref(false)
    const onSetVisible = data => {
      suggestVisible.value = data
    }
    // Register module
    if (!store.hasModule(RESERVATION_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        RESERVATION_APP_STORE_MODULE_NAME,
        reservationStoreModule,
      )
    }

    const {
      sourcesCanAutoIssue,
      isHideSupportRequest,
      sourcesCanLowFareTracking,
      isHideLowFareTracking,
      isHideResell,
    } = env

    const {
      retrieveBooking,
      fetchBookingById,
      fetchPastDayBookingById,
      resetStore,
      getBookingData,
      loading, loadingRefetchBooking,
      refetchBookingData,
      shortenBooking: getShortenBooking,
      deActiveReservation, // for show/hide booking
      activeReservation, // for show/hide booking
      enableEditOutside,
      hideFeature, toggleHideFeature,
    } = useReservationHandle()
    const { airlineSupports: airlineSupportsCC } = useSupportReqHandle()
    resetStore()
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RESERVATION_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(RESERVATION_APP_STORE_MODULE_NAME)
      }
    })

    function retrieveBookingHandle(source, pnrCode, airline = undefined) {
      return retrieveBooking({ source, pnrNumber: pnrCode, airline })
        .catch(() => {
          toastError({ title: 'reservation.message["Booking not found"]' })
          router.replace({ name: 'apps-reservations-list' })
        })
    }
    const reservationsData = ref()
    const bookingText = ref()
    const historyText = ref()
    const baggagesText = ref()
    const fareRulesText = ref()
    const isBookingOutSystem = ref(false) // Check code ngoai he thong
    const isBookingDeactive = ref(false) // Check code dong bo he thong nhung chua active
    const isHideFunctionButton = ref(false) // Check có hiển thị button tính năng hay ko? ví dụ: draft booking
    const canIssueLimit = ref() // Check agency co duoc modify hay ko
    const isLockedModify = ref(false) // Hệ thống VN khoá xuất vé và modify từ 23h30-24h00
    const getEnv = computed(() => store.getters['app/getEnv'])
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])
    const f2CanSetLowFare = computed(() => store.getters['userStore/getMeData']?.agency?.enableLowFareTracking || false)
    const isHideEmailButton = computed(() => ['AK', 'F1', 'TH', '1A'].includes(getBookingData.value?.source) || isHideFunctionButton.value)

    // F3 hidefee, sendMailAirline = false ko hiển thị nút gửi mail
    const isF3canSendMailAirline = computed(() => {
      if (isRoleF3.value && store.getters['userStore/getAgencyData']?.sendMailAirline === false) {
        return false
      }
      return true
    })

    function canSetLowFare() {
      if (!getBookingData.value || isHideLowFareTracking || isRoleF3.value) return false
      if (!sourcesCanLowFareTracking.includes(getBookingData.value.source)) return false
      if (!['HOLD', 'CANCEL'].includes(getBookingData.value.status)) return false
      return true
    }

    const loadingShortenBooking = ref(false)
    function getShortenBookingHandle() {
      loadingShortenBooking.value = true
      historyText.value = null
      baggagesText.value = null
      fareRulesText.value = null

      if (['1S', '1S_CTRL', '1G', 'QH'].includes(reservationsData.value.source)) {
        getShortenBooking({
          source: reservationsData.value.source,
          pnrNumber: reservationsData.value.bookingCode,
        })
          .then(res => {
            let historyRes
            let baggagesRes
            let fareRulesRes

            if (reservationsData.value.source.includes('1S')) {
              if (res.booking) {
                historyRes = res?.booking.pop()
                baggagesRes = res?.baggages?.join('\n') || 'NO RECORDS DATA'
                fareRulesRes = res?.fareRules?.join('\n') || 'NO RECORDS DATA'
              } else {
                historyRes = res.pop()
                baggagesRes = false
                fareRulesRes = false
              }
            }

            bookingText.value = res?.booking ? res?.booking?.join('\n') || 'NO RECORDS DATA' : res?.join('\n') || 'NO RECORDS DATA'
            historyText.value = historyRes
            baggagesText.value = baggagesRes
            fareRulesText.value = fareRulesRes
          })
          .finally(() => {
            loadingShortenBooking.value = false
          })
      } else {
        const reservationDataInStore = computed(() => store.getters[`${RESERVATION_APP_STORE_MODULE_NAME}/getBookingData`])
        let itinerariesText = ''
        reservationDataInStore.value.itineraries.forEach(trip => {
          trip.forEach(segment => {
            itinerariesText += `\t${convertShortTrip(segment)}\n`
          })
        })
        let paxsText = ''
        let numberTickets = ''
        reservationDataInStore.value.paxLists.forEach((pax, index) => {
          paxsText += `\t${index + 1}. ${pax.lastName} ${pax.firstName} ${pax.title}\n`
          if (pax?.eticket?.length && ['QH', 'VN1A', 'VN1A_MT'].includes(reservationDataInStore.value.source)) {
            numberTickets += `\t${pax.lastName} ${pax.firstName} ${pax.title}: ${pax.eticket.map(eticket => `${eticket.number}` || '').join('\n')}\n`
          }
        })
        const totalAmount = reservationDataInStore.value.totalAmountPaid + reservationDataInStore.value.totalAmountToBePaid

        const locale = computed(() => VueI18n.locale).value
        bookingText.value = `${locale === 'vi' ? 'Mã đặt chỗ' : 'Booking code'}: ${reservationDataInStore.value?.bookingCode}\n${locale === 'vi' ? 'Hành trình' : 'Itineraries'}:\n${itinerariesText}${locale === 'vi' ? 'Hành khách' : 'Passengers'}:\n${paxsText}${numberTickets ? `${locale === 'vi' ? 'Danh sách số vé' : 'Etickets'}:\n${numberTickets}` : ''}${locale === 'vi' ? 'Số điện thoại' : 'Phone'}: ${`${reservationDataInStore.value.contact?.paxInfo?.phone?.[0]?.prefix ? `${reservationDataInStore.value.contact?.paxInfo?.phone?.[0]?.prefix} ` : ''}`}${reservationDataInStore.value.contact?.paxInfo?.phone?.[0]?.address ?? reservationDataInStore.value.contact?.paxInfo?.phone?.[0] ?? ''}\n${locale === 'vi' ? 'Email' : 'Email'}: ${reservationDataInStore.value.contact?.paxInfo?.email?.[0]?.address ?? reservationDataInStore.value.contact?.paxInfo?.email?.[0]}\n${locale === 'vi' ? 'TỔNG TIỀN' : 'TOTAL'}: ${formatCurrency(totalAmount)}`
        loadingShortenBooking.value = false
      }
    }

    const isPastDayBooking = computed(() => router.currentRoute.name === 'apps-past-day-booking-modify')

    const fetchBooking = async id => {
      const idSplit = id.toString().split('-')
      let res
      if (idSplit.length === 1) {
        if (isPastDayBooking.value) {
          res = await fetchPastDayBookingById(id)
        } else {
          res = await fetchBookingById(id)
        }
        paxLists.value = res.data.paxLists
        airlineCode.value = res.data.airlines
        reservationsData.value = res.data
        canIssueLimit.value = await store.getters['userStore/canIssueLimit'](res.data.source)
        isBookingOutSystem.value = reservationsData.value?.isOutside && !reservationsData.value?.enableEditOutside
        isBookingDeactive.value = !reservationsData.value?.enableEditOutside
        isHideFunctionButton.value = reservationsData.value?.status === 'DRAFT'
      } else {
        res = await retrieveBookingHandle(idSplit[0], idSplit[1], idSplit[2] || undefined)
        reservationsData.value = res
        isBookingOutSystem.value = true
      }
      if (isPastDayBooking.value) {
        isBookingOutSystem.value = true
      }
      if (reservationsData.value.source.includes('1S')) {
        const now = new Date()
        const hour = now.getHours()
        const min = now.getMinutes()
        const isLockedTime = hour === 23 && min >= 30
        const isDev = computed(() => getEnv.value === 'development' || getEnv.value === 'staging')
        const userType = computed(() => store.getters['userStore/getMeDataType'])
        const isTypeF1CanModify = computed(() => isRoleF1.value && ['ADM', 'BE', 'OPE'].includes(userType.value))
        isLockedModify.value = !!(isLockedTime && !isDev.value && !isTypeF1CanModify.value)
      }
    }

    if (router.currentRoute.params.id) {
      fetchBooking(router.currentRoute.params.id)
    }

    function openSetIssue(event, bookingData) {
      if (bookingData.isOutside) {
        toastError({
          title: 'Hệ thống không hỗ trợ tự động xuất vé nhập ngoài hệ thống!',
        })
        return
      }

      if (isEmpty(bookingData.itineraries)) {
        toastError({
          title: 'Không tìm thấy hành trình trong mã đặt chỗ!',
        })
        return
      }

      this.$root.$emit('bv::show::modal', 'reservation-set-auto-issue-modal', event)
    }

    function openAssignBooking(event) {
      this.$root.$emit('bv::show::modal', 'assign-booking-modal', event)
    }

    function handleShowModalSendEmail(event) {
      const bookingData = getBookingData.value

      if (bookingData.source === 'QH' && !bookingData.status.includes('PAID')) {
        toastError({ title: 'Vui lòng thanh toán vé để thực hiện !' })
        return
      }

      this.$root.$emit('bv::show::modal', 'id-modal-reservation-send-email', event)
    }

    function handleShowModalLowFareTracking() {
      // Tắt canh vé rẻ
      // toastError({
      //   title: 'Chức năng tạm thời đang tắt !',
      // })
      // return

      const bookingData = getBookingData.value

      if (isRoleF2.value && !f2CanSetLowFare.value) {
        toastError({
          title: 'Bạn chưa được cấp quyền truy cập chức năng này, vui lòng liên hệ Đại lý cấp trên!',
        })
        return
      }

      if (!bookingData.bookingRequest) {
        toastError({ title: 'Chưa hỗ trợ canh vé cho các mã đặt chỗ ngoài hệ thống, mã đặt chỗ tách hành khách, ...' })
        return
      }

      const hasInfant = bookingData.paxLists.find(pax => ['INFANT'].includes(pax.paxType))
      if (hasInfant) {
        toastError({ title: 'Chưa hỗ trợ canh vé cho các mã đặt chỗ có em bé.' })
        return
      }

      const hasExternalTrip = !['OW'].includes(bookingData.flightType) && bookingData.itineraries.some(trip => isDomesticFlight(trip[0].departure.iataCode, trip[trip.length - 1].arrival.iataCode))

      if (hasExternalTrip) {
        toastError({ title: 'Không hỗ trợ canh vé các mã đặt chỗ khứ hồi, nhiều chặng đi quốc tế.' })
        return
      }

      this.$bvModal.show('modal-modify-low-fare-tracking')
    }

    watch(() => getBookingData.value, (newData, oldData) => {
      if (newData && oldData) {
        if (newData.bookingCode !== oldData.bookingCode) {
          store.dispatch('app-reservation/removeSeatMapData')
          store.dispatch('app-reservation/removeListAncillaryData')
        }
      }
      if (newData?.bookingCode !== oldData?.bookingCode && isRoleF2.value && !isHideSupportRequest) {
        apiSupportRequest.getByCode(getBookingData.value.bookingCode).then(rs => {
          supportReqId.value = rs.data.id
        })
      }
    }, { deep: true })

    // ANCHOR handle show/hide booking
    function showConfirmToggleHideBooking({ bookingCode, id }, type) {
      const titleMsg = () => this.$createElement('div', {
        domProps: {
          // innerHTML: `<span>Bạn chắc chắn muốn ${type ? 'hiện' : 'ẩn'} mã đặt chỗ <em class='font-weight-bolder'>${bookingCode}</em>?</span>`,
          innerHTML: `<span> ${root.$t('reservation.hideBookingConfirm', { type: root.$t(!type ? 'reservation.Hide' : 'reservation.Show') })} <em class='font-weight-bolder'>${bookingCode}</em>?</span>`,
        },
      })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'success',
          okTitle: this.$t('modal.confirm'),
          cancelTitle: this.$t('modal.cancel'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            if (type) {
              activeReservation(id, 'detail')
            } else {
              deActiveReservation(id, 'detail')
            }
          }
        })
    }

    // Check hành trình có status SC không?
    const isItinerariesSC = computed(() => getBookingData.value?.itineraries?.some(trip => trip.some(seg => seg.status === 'SC')))

    // Check load booking từ hãng fail hay ko ?
    const isLoadBookingFail = computed(() => getBookingData.value?.isLoadBookingFail)

    function handleUnlockOutsideBooking() {
      const pnrString = reservationsData.value.bookingCode
      const customerToAssign = reservationsData.value.paxContact
      const assigneeString = reservationsData.value.agency !== `AGC-${MAMA_AGENCY_ID}` ? `${this.$t('Agency')} ${reservationsData.value.agency} (${reservationsData.value.createdBy.agency.agencyName})` : `${this.$t('customers')} ${customerToAssign?.code} (${customerToAssign?.lastName} ${customerToAssign?.firstName})`
      Vue.swal({
        title: 'Xác nhận mở khoá booking',
        html: `
          <span class="font-weight-bold text-16px">
            Anh/chị chắc chắn muốn mở khoá booking <code class="code-agency">${pnrString}</code> đồng bộ cho <span class="font-weight-bolder text-danger">${assigneeString}</span>?
            </span>
          `,
        icon: 'question',
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: 'Không',
        confirmButtonText: 'Đồng ý',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger mr-50',
        },
        buttonsStyling: false,
        reverseButtons: true,
      }).then(result => {
        if (result.value) {
          enableEditOutside(router.currentRoute.params.id).then(() => {
            isBookingDeactive.value = false
            isBookingOutSystem.value = false
          })
        }
      })
    }

    const dataFareRules = ref([])
    // const loadingFareRules1G = ref(false)

    async function getFareRulesHandle(booking) {
      dataFareRules.value = booking.itineraries.map((trip, indexTrip) => ({ trip, indexTrip, fareRules: null }))
      if (dataFareRules.value?.some(t => !t.fareRules)) {
        // loadingFareRules1G.value = true
        const promises = dataFareRules.value.map(async trip => {
          const payload = {
            contact: booking.paxContact.id,
            source: booking.source,
            itinerary: trip.trip.map(segment => ({
              departDate: convertISODateTime(segment.departure.at, segment.departure.timeZone).ISOdatetime,
              departure: segment.departure.iataCode,
              arrival: segment.arrival.iataCode,
              fareBasisCode: '',
            })),
            pnrNumber: booking.bookingCode,
            contentSource: booking?.extra[0]?.contentSource,
          }

          const res = await apiBooking.getFareRules(payload)

          return {
            ...trip,
            fareRules: isEmpty(res) ? 'error' : res?.join('\n'),
          }
        })

        const results = await Promise.all(promises)

        results.forEach(data => {
          const index = dataFareRules.value.findIndex(i => i.indexTrip === data.indexTrip)
          const cloneData = cloneDeep(dataFareRules.value)
          cloneData[index] = data
          dataFareRules.value = cloneData
        })
        // loadingFareRules1G.value = false
      }
    }

    function editNote(event) {
      this.$root.$emit(
        'bv::show::modal',
        'reservation-modify-change-note-modal',
        event,
      )
    }

    return {
      getBookingData,
      isRoleF2,
      refetchBookingData,
      fetchBooking,
      openSetIssue,
      openAssignBooking,
      handleShowModalSendEmail,
      bookingText,
      historyText,
      baggagesText,
      fareRulesText,
      reservationsData,
      isBookingOutSystem,
      isPastDayBooking,
      isBookingDeactive,
      canIssueLimit,
      isLockedModify,
      getShortenBookingHandle,
      getFareRulesHandle,
      convertISODateTime,
      dataFareRules,
      loading,
      loadingShortenBooking,
      loadingRefetchBooking,

      isEmpty,
      canSetLowFare,
      handleShowModalLowFareTracking,

      isF3canSendMailAirline,
      showConfirmToggleHideBooking,
      isItinerariesSC,
      handleUnlockOutsideBooking,
      isRoleF1,
      isLoadBookingFail,
      isHideEmailButton,
      isHideFunctionButton,
      editNote,
      hideFeature,
      toggleHideFeature,
      paxLists,
      suggestVisible,
      onSetVisible,
      airlineCode,
      airlineSupportsCC,
      supportReqId,
      getEnv,
      sourcesCanAutoIssue,
      isHideSupportRequest,
      isHideResell,
    }
  },
  watch: {
    $route(to) {
      this.fetchBooking(to.params.id)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.overlay-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 1200px) {
  .overlay-center {
    left: 57%;
  }
}
</style>
